import React, { useState, useEffect } from 'react';
import FileUploadForm from '../../helpers/FileUploadForm';
import ImageList from '../../components/ImageList';
import { RenderContentField } from './RenderContentField';
import { buildEbay, createHTML, createInvoice, updateEbay, CreateDHLLabel, createReturn } from './socketIOOperations';
import clipboardCopy from 'clipboard-copy';
import InvoicePrinter from '../../helpers/InvoicePrinter';
import './renderFields.css';
import { EbayAdCreator } from './EbayAdCreator';
import { PrePrinter } from './misc/PrePrinter';
import LabelPrinter from '../../helpers/Labelprinter';
import { saveChanges } from '../../controller/saveChanges';
const CopyButton = ({ content }) => {
      const handleCopy = () => {
            clipboardCopy(content)
                  .then(() => {
                        // Display a message or update state to indicate success
                        alert('Content copied to clipboard!');
                  })
                  .catch((err) => {
                        // Handle possible errors
                        console.error('Failed to copy:', err);
                  });
      };

      return (
            <button onClick={handleCopy} className="btn btn-primary" style={{ backgroundColor: '#1AA99D', borderColor: '#1AA99D', width: '130px' }}>
                  Copy Content
            </button>
      );
};

export const DetailedField = ({ fieldNames, item, index, handleInputChange, ebayData, setPurchaseData, setReceiveInvoiceDataObject }) => {
      const [isVisible, setIsVisible] = useState(false);
      const [itemState, setItemState] = useState(false);
       console.log('shipping address',item);

      useEffect(() => {
            setItemState(item);
      }, [item]);

      useEffect(() => {
            // Component did mount
            setIsVisible(true);

            // Component will unmount
            return () => {
                  setIsVisible(false);
            };
      }, []);

      const ebaySingleData = ebayData.find((ebayItem) => ebayItem.OrderID === item.EbaySale);
      console.log(ebaySingleData, 'this');

      if (ebaySingleData?.ShippingAddress && !item?.ShippingAddress) {
           
            item.ShippingAddress = ebaySingleData.ShippingAddress;
            item.ShippingAddress.email = ebaySingleData.TransactionArray.Transaction[0].Buyer.Email;
            saveChanges([item], false, true);
      }
      return (
            <tr>
                  {/* First block spanning two columns */}
                  <td colSpan="5">
                        {' '}
                        {/* Adjust the width if necessary */}
                        <div
                              className={isVisible ? 'roll-out-effect visible' : 'roll-out-effect'}
                              style={{
                                    width: '100%',
                                    transition: 'all 0.1s ease-out'
                              }}
                        >
                              {fieldNames
                                    .find((field) => field.type === 'foldableMenu')
                                    .content.map((contentField, contentIndex) => (
                                          <RenderContentField
                                                key={contentIndex} // Always use keys when rendering lists for better performance and consistency
                                                item={item}
                                                itemIndex={contentIndex} // Assuming 'index' is defined correctly in the outer scope
                                                contentField={contentField}
                                                contentIndex={index}
                                                handleInputChange={handleInputChange}
                                          />
                                    ))}
                              <div>
                                    <FileUploadForm currentProductObj={item} />
                                    {item.images && <ImageList fileArray={item} />}
                                    <div className="mb-3">
                                          {' '}
                                          {/* Wrapper div for margin */}
                                          <button
                                                onClick={() => createHTML(item)}
                                                className="btn btn-primary me-2" // Bootstrap class for margin-right
                                                style={{
                                                      backgroundColor: '#1AA99D',
                                                      borderColor: '#1AA99D',
                                                      width: '160px'
                                                }}
                                          >
                                                Create template
                                          </button>
                                          {itemState.HTMLObjectJSON && (
                                                <CopyButton
                                                      content={itemState.HTMLObjectJSON}
                                                      style={{ width: '130px' }} // Ensure consistent width
                                                />
                                          )}
                                          {
                                                <button
                                                      onClick={() => buildEbay(itemState, setPurchaseData)}
                                                      className="btn btn-primary me-2" // Bootstrap class for margin-right
                                                      style={{
                                                            marginLeft: '10px',
                                                            backgroundColor: '#1AA99D',
                                                            borderColor: '#1AA99D',
                                                            width: '160px'
                                                      }}
                                                >
                                                      {!item.ebayAdId ? 'Create ebay ad' : 'Update ebay ad'}
                                                </button>
                                          }
                                          {item.HTMLObjectJSON && item.ebayAdId && <PrePrinter item={item} />}
                                    </div>
                              </div>
                        </div>
                  </td>

                  <td colSpan="2">
                        {item.EbaySale && ebaySingleData && (
                              <div>
                                    <button
                                          onClick={() => window.open(`https://www.ebay.de/itm/${ebaySingleData.TransactionArray.Transaction[0].Item.ItemID}`, '_blank')}
                                          className="btn btn-primary me-2" // Bootstrap class for margin-right
                                          style={{
                                                backgroundColor: '#1AA99D',
                                                borderColor: '#1AA99D',
                                                width: '260px'
                                          }}
                                    >
                                          View linked item on eBay
                                    </button>
                                    <h1>Purchase Details</h1>
                                    <ul>
                                          <li>Product Name: {ebaySingleData.TransactionArray.Transaction[0].Item.Title}</li>
                                          <li>
                                                Purchase Price: {ebaySingleData.TransactionArray.Transaction[0].TransactionPrice.value}{' '}
                                                {ebaySingleData.TransactionArray.Transaction[0].TransactionPrice.currencyID}
                                          </li>
                                          {fieldNames
                                                .find((field) => field.type === 'foldableMenu-Section2')
                                                .content.map((contentField, contentIndex) => (
                                                      <RenderContentField
                                                            key={contentIndex} // Always use keys when rendering lists for better performance and consistency
                                                            item={item}
                                                            itemIndex={contentIndex} // Assuming 'index' is defined correctly in the outer scope
                                                            contentField={contentField}
                                                            contentIndex={index}
                                                            handleInputChange={handleInputChange}
                                                      />
                                                ))}
                                          <li>Purchase Date: {new Date(ebaySingleData.PaidTime).toLocaleDateString()}</li>
                                          <li>Status: {ebaySingleData.OrderStatus}</li>
                                    </ul>
                              </div>
                        )}

                        {!item.invoiceNr && (
                              <button
                                    className="btn btn-primary me-2" // Bootstrap class for margin-right
                                    style={{
                                          backgroundColor: '#1AA99D',
                                          borderColor: '#1AA99D',
                                          width: '260px'
                                    }}
                                    onClick={() => createInvoice(item, setReceiveInvoiceDataObject)}
                              >
                                    Create Invoice
                              </button>
                        )}

                        {item.invoiceNr && <InvoicePrinter invoiceId={item.invoiceNr} />}
                        {item.EbaySale && !item.shipmentNo && (
                              <button
                                    className="btn btn-primary me-2" // Bootstrap class for margin-right
                                    style={{
                                          backgroundColor: '#1AA99D',
                                          borderColor: '#1AA99D',
                                          width: '260px'
                                    }}
                                    onClick={() => CreateDHLLabel(item, setPurchaseData)}
                              >
                                    Create label
                              </button>
                        )}
                        {item.invoiceNr && (
                              <button
                                    className="btn btn-primary me-2" // Bootstrap class for margin-right
                                    style={{
                                          backgroundColor: '#1AA99D',
                                          borderColor: '#1AA99D',
                                          width: '260px'
                                    }}
                                    onClick={() => createReturn(item, setPurchaseData)}
                              >
                                    Return
                              </button>
                        )}
                        {item.shipmentNo && <LabelPrinter DHLLabel={item.shipmentNo} />}
                  </td>

                  <td colSpan="4"></td>
            </tr>
      );
};
